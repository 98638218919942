import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const useFAQData = previewData => {
  const staticData = useStaticQuery(
    graphql`
      query {
        prismicFaqPage {
          _previewable
          uid
          data {
            title {
              html
            }
            tagline {
              text
            }
            image {
              fluid {
                aspectRatio
                sizes
                src
                srcSet
              }
              alt
            }
            body {
              ... on PrismicFaqPageBodyBook {
                slice_type
                primary {
                  text {
                    text
                  }
                  link {
                    url
                    link_type
                  }
                  button_text
                }
              }
            }
          }
        }
        allPrismicFaqGroup {
          nodes {
            data {
              group_type
              questions_and_answers {
                answer {
                  text
                  html
                }
                question {
                  text
                }
                faq_navigation_title
              }
              faq_group_text {
                text
              }
              faq_title {
                text
              }
            }
          }
        }
      }
    `
  )
  const mergedData: any = mergePrismicPreviewData({
    staticData,
    previewData,
    strategy: "rootReplaceOrInsert",
  })
  const groups = mergedData.allPrismicFaqGroup.nodes.map(node => node.data)
  if (mergedData.prismicFaqGroup) {
    groups.push(mergedData.prismicFaqGroup.data)
  }
  const { data, uid: pageTitle } = mergedData.prismicFaqPage

  return { ...data, pageTitle, groups }
}
