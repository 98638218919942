import React, { useState } from "react"
import styled from "styled-components"
import { Span } from "../atoms/text"

const FaqNavigation = ({ setFaqGroup, navigation, refs }) => {
  const [activeNav, setActiveNav] = useState(0)
  const executeScroll = index => {
    const y = refs.current[index].offsetTop - 4
    window.scrollTo({ top: y - 84, behavior: "smooth" })
  }
  return (
    <Container>
      {navigation.map((navItem, i) => {
        return (
          <FAQGroup key={i}>
            <MainNav
              onClick={() => {
                setFaqGroup(i)
                setActiveNav(i)
              }}
            >
              {navItem.name}
            </MainNav>
            {navItem.subNav.map((sub, j) => {
              return (
                <SubNav
                  active={activeNav === i}
                  onClick={() => executeScroll(j)}
                  key={j}
                >
                  <Span active={0}>{sub}</Span>
                </SubNav>
              )
            })}
          </FAQGroup>
        )
      })}
    </Container>
  )
}

export default FaqNavigation

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 700px) {
    padding: 0 0 32px 0;
  }
`
const FAQGroup = styled.div`
  padding-bottom: 8px;
  width: max-content;
`
const MainNav = styled.p`
  font-family: "Moderat Bold", sans-serif;
  color: var(--primary-text);
  cursor: pointer;
  width: max-content;
  position: relative;
  transition: width 300ms, transform 300ms;
  ::after {
    transition: width 300ms, transform 300ms;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: var(--primary-text);
  }
  :hover::after {
    transition: width 300ms, transform 300ms;
    width: 100%;
  }
  :active {
    color: var(--primary-blue);
  }
`
const SubNav = styled.p<{ active: boolean }>`
  text-transform: capitalize;
  font-family: "Moderat Regular", sans-serif;
  color: var(--primary-text);
  padding: 4px 8px 4px 16px;
  cursor: pointer;
  display: ${({ active }) => (active ? "block" : "none")};
  text-decoration: none;
`
